<template>
  <div ref="modal" size="lg" v-if="showModal" hide-footer>
    <form @submit.prevent="submit" class="form-create-member border border-secondary">
      <b-row v-if="settings.personnr">
        <b-col md="6">
          <b-form-group :label="$t('MEMBER.PERSONNR')" label-for="fa-person-number">
            <b-form-input
              :disabled="local_form.id === 0"
              id="fa-person-number"
              v-model="local_form.personnr"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="settings.dob">
        <b-col md="6">
          <b-form-group :label="$t('MEMBER.DOB')" label-for="fa-dob">
            <b-form-input
              :disabled="local_form.id === 0"
              id="fa-dob"
              v-model="local_form.dob"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('MEMBER.FIRSTNAME')" label-for="fa-firstname">
            <b-form-input
              :disabled="local_form.id === 0"
              id="fa-firstname"
              v-model="local_form.firstname" 
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="$t('MEMBER.LASTNAME')" label-for="fa-lastname">
            <b-form-input
              :disabled="local_form.id === 0"
              id="fa-lastname"
              v-model="local_form.lastname"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="settings.email">
        <b-col md="6">
          <b-form-group
            :label="$t('MEMBER.EMAIL')" label-for="fa-email">
            <b-form-input
              :disabled="local_form.id === 0"
              id="fa-email"
              v-model.trim="local_form.email"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="settings.phone" md="6">
          <b-form-group :label="$t('MEMBER.MOBILE')" label-for="fa-phone">
            <b-form-input
              :disabled="local_form.id === 0"
              id="fa-phone"
              v-model="local_form.phone"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="settings.address && me && local_form.id !== 0">
        <b-col md="12" class="d-flex align-items-center mb-7">
          <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input
              type="checkbox"
              name="use-other-address"
              v-model="local_form.use_other_address"
            />
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer">Använd annan adress</span>
        </b-col>
      </b-row>

      <b-row v-if="settings.address">
        <b-col md="12">
          <b-form-group :label="$t('MEMBER.ADDRESS')" label-for="fa-address">
            <b-form-input
              :disabled="local_form.id === 0"
              id="fa-address"
              v-model="local_form.address"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="settings.zipcode">
        <b-col md="6">
          <b-form-group :label="$t('MEMBER.ZIPCODE')" label-for="fa-zipcode">
            <b-form-input
              :disabled="local_form.id === 0"
              id="fa-zipcode"
              v-model="local_form.zipcode"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="$t('MEMBER.POST')" label-for="fa-post">
            <b-form-input
              id="fa-post"
              :disabled="local_form.id === 0"
              v-model="local_form.post"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="settings.region || settings.commune">
        <b-col md="6">
          <b-form-group :label="$t('MEMBER.REGION')" label-for="fa-region_code">
            <b-form-select
              id="fa-region_code"
              :disabled="local_form.id === 0"
              v-model="local_form.region_code"
              :options="regionOptions"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="settings.commune" md="6">
          <b-form-group :label="$t('MEMBER.COMMUNE')" label-for="fa-commune_code">
            <b-form-select
              id="fa-commune_code"
              :disabled="local_form.id === 0"
              v-model="local_form.commune_code"
              :options="communeOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="local_form.id !== 0 && settings.company_association">
        <b-col md="12">
          <b-form-group :label="$t('MEMBER.COMPANY_NAME')" label-for="fa-company_association">
            <b-form-select
              id="fa-region_code"
              v-model="local_form.company_id"
              :options="companyOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-6">
        <b-col class="d-flex justify-content-end">
          <b-button variant="outline-success" class="m-1" type="submit">
            {{ $t('COMMON.SAVE') }}
          </b-button>
          <b-button variant="outline-primary" class="m-1" @click="cancel"> {{ $t('COMMON.CANCEL') }} </b-button>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import validate_pers_number from '@/core/services/personnr';


export default {
  name: 'RegisterFamilyForm',
  props: ['company_id', 'editData', 'settings', 'regions', 'me', 'area_alloc'],
  emits: ['save', 'cancel', 'visible'],
  data() {
    return {
      showModal: false,
      communeOptions: [],
      companyOptions: [],
      communes: {},
      local_form: {
        id: 0,
        firstname: '',
        lastname: '',
        personnr: '',
        dob: '',
        email: '',
        phone: '',
        type: 'FA',
        address: '',
        zipcode: '',
        region_code: '',
        commune_code: '',
        company_id: '',
        post: '',
        head: false,
        use_other_address: false
      }
    };
  },
  
  created() {
    this.setCompanyIds();
  },
  computed: {
    ...mapGetters(['companies', 'memberCompanies']),
    regionOptions() {
      const opts = [];

      for (var k in this.regions) {
        opts.push({ value: k, text: this.regions[k].region });
      }

      opts.sort(function(a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });

      return opts;
    },
    disabled() {
      return this.local_form.id !== 0;
    }
  },
  methods: {

    submit() {
      this.$emit('save', this.local_form);
      this.showModal = false;
    },
    
    show() {
      if (!this.data) this.resetForm();
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    cancel() {
      this.showModal = false;
      this.$emit('cancel');
    },
    save() {
      this.showModal = false;
      this.$emit('save', this.local_form);
    },
    setData(data) {
      this.local_form = JSON.parse(JSON.stringify(data));
    },
    setCompanyIds() {
      let companyOpts;

      if (this.settings.selected_company_ids) {
        companyOpts = this.memberCompanies.filter(c =>
          this.settings.selected_company_ids.includes(c.company_id)
        );
      } else if (this.companies) {
        companyOpts = this.companies;
      } else {
        companyOpts = this.memberCompanies;
      }

      const opts = [];

      if (
        this.area_alloc &&
        this.area_alloc.company_ids &&
        this.area_alloc.company_ids.length !== 0
      ) {


        opts.push({
          value: this.area_alloc.company_ids[0],
          text: this.memberCompanies.find(c => c.company_id === this.area_alloc.company_ids[0]).name
        });
      }

      this.companyOptions = opts.concat(
        companyOpts.map(c => ({
          value: c.company_id,
          text: c.name
        }))
      );

      if (this.companyOptions.length > 0) {
        this.local_form.company_id = this.companyOptions[0].value;
      }
    },
    resetForm() {
      this.local_form = {
        firstname: '',
        lastname: '',
        personnr: '',
        email: '',
        phone: '',
        type: 'FA',
        address: '',
        zipcode: '',
        post: '',
        region_code: '',
        commune_code: '',
        head: false,
        use_other_address: false
      };
      
      if (this.me.address) {
        this.local_form.address = this.me.address;
      }
      if (this.me.zipcode) {
        this.local_form.zipcode = this.me.zipcode;
      }
      if (this.me.post) {
        this.local_form.post = this.me.post;
      }
      if (this.me.region_code) {
        this.local_form.region_code = this.me.region_code;
      }
      if (this.me.commune_code) {
        this.local_form.commune_code = this.me.commune_code;
      }

      if (this.companyOptions.length > 0) {
        this.local_form.company_id = this.companyOptions[0].value;
      }
    },
    toggleOtherAddress() {
      if (!this.me) return;

      // this.use_other_address = !this.use_other_address;

      this.local_form.address = this.local_form.use_other_address
        ? ''
        : this.me.address || '';
      this.local_form.zipcode = this.local_form.use_other_address
        ? ''
        : this.me.zipcode || '';
      this.local_form.post = this.local_form.use_other_address ? '' : this.me.post || '';
      this.local_form.region_code = this.local_form.use_other_address
        ? ''
        : this.me.region_code || '';
      this.local_form.commune_code = this.local_form.use_other_address
        ? ''
        : this.me.commune_code || '';
    },
    loadCommunes(selectedRegion) {

      this.communeOptions = [];

      var communes = this.regions[selectedRegion].communes;
      this.communes = communes;

      for (var k in communes) {
        this.communeOptions.push({ value: k, text: communes[k] });
      }

      this.communeOptions.sort(function(a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    }
  },
  watch: {
    'local_form.personnr': function(newVal, oldVal) {
      let formatted = validate_pers_number(newVal);
      if (formatted.valid && this.local_form.personnr !== formatted.personnr) {
        this.local_form.personnr = formatted.formatted;
      }
    },
    'local_form.region_code': function(newValue) {
      this.local_form.region_code = newValue;

      if (
        this.local_form.region_code === null ||
        this.local_form.region_code === undefined ||
        this.local_form.region_code === ''
      ) {
        console.error('empty region code');
        return;
      }

      if (newValue) {
        this.loadCommunes(newValue);
      }
    },
    'local_form.use_other_address'(newVal) {
      if (!this.me || this.local_form.id === 0) return;

      this.local_form.address = newVal ? '' : this.me.address || '';
      this.local_form.zipcode = newVal ? '' : this.me.zipcode || '';
      this.local_form.post = newVal ? '' : this.me.post || '';
      this.local_form.region_code = newVal ? '' : this.me.region_code || '';
      this.local_form.commune_code = newVal ? '' : this.me.commune_code || '';
    },
    area_alloc: function(newVal, oldVal) {
      if (
        !newVal ||
        !newVal.company_ids ||
        newVal.company_ids.length === 0 ||
        (oldVal && newVal.company_ids[0] === oldVal.company_ids[0])
      )
        return;

      this.setCompanyIds();
    },
    showModal(new_val) {
      this.$emit('visible', new_val)
    }
  }
};
</script>

<style lang="scss" scoped>
.form-create-member {
  max-width: 800px;
  margin: 0 auto;
  padding: 25px;
}
</style>
